import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment';

import * as callActions from '../../redux/call/actions';
import * as callListActions from '../../redux/call-lists/actions';
import * as contactActions from '../../redux/contacts/actions';

import { BOOKER } from '../../consts';

import { ContactHeaderName } from '../'

import { getPath } from '../../redux/utils/utils.saga';

import { CONTACT_BOOKING_IDS } from '../../utils';

export class ContactHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    if ( this.props.onRef ) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if ( this.props.onRef ) {
      this.props.onRef(undefined)
    }
  }

  handleClickCall() {
    if (this.props.call.active) {
      if ( this.props.useFlow || this.props.currentCallType === 'confirmation' ) {
        this.setState({ showModal: true });
      } else {
        this.props.endCall(
          false, 
          this.props.currentCallType, 
          this.props.booking ? this.props.booking.id : null
        );
      }
    } else {
      this.props.startCall(
        this.props.contact.phoneNumber, 
        this.props.currentCallType,
        this.props.contactIdParam
          ? parseInt(this.props.contactIdParam, 10)
          : undefined
      );
    }
  }

  closeModal(answered) {
    this.setState({ showModal: false });
    this.props.endCall(answered, this.props.currentCallType, this.props.booking ? this.props.booking.id : null);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.parent !== undefined && nextProps.status !== this.props.status && this.props.siblingSavePending ) {
      this.handleClickNext(true)
      this.props.parent.setState({savePending: false})
    }
  }

  isConfirmationCase() {
    return this.props.currentCallType === 'confirmation'
      && this.props.booking
      && this.props.booking.confirmationCallAttempts
      && this.props.booking.confirmationCallAttempts.length >= 2
      && this.props.call.finished 
      && !this.props.call.answered
  }

  handleClickNext(noCheck=false, bypassExtFn=false) {
    if ( this.props.onClickNext && !bypassExtFn ) {
      this.props.onClickNext(this)
      return
    }
    let updateCallTypeLists = {
      callback: true,
      tentative: true,
      noShow: {
        onlyOwn: this.props.onlyOwn,
        includeNotInterested: this.props.includeNotInterested,
        selectedNoShowServicePoint: this.props.selectedNoShowServicePoint,
        selectedNoShowPostalAreas: this.props.selectedNoShowPostalAreas,
        selectedNoShowRepresentatives: this.props.selectedNoShowRepresentatives,
        selectedNoShowBooker: this.props.selectedNoShowBooker,
        selectedNoShowYear: this.props.selectedNoShowYear,
        selectedNoShowMonth: this.props.selectedNoShowMonth,
        selectedNoShowStatus: this.props.selectedNoShowStatus,
        noShowSokContacts: this.props.noShowSokContacts
      },
      confirmation: true,
      statistics: { role: BOOKER }
    }

    if ( 
      !noCheck &&
      (!this.props.currentCallType || this.props.currentCallType === 'booking') &&
      this.props.selectedProject.allowSkipping &&
      !this.props.call.active &&
      !this.props.reservation &&
      !this.props.call.finished &&
      this.props.contactMainStatusId === 1 
    ) {

      this.props.resetCurrentCallType();
      this.props.getContactCard(updateCallTypeLists, this.props.contact.id);

    } else if ( !noCheck && this.props.parent && ( 
        (
          CONTACT_BOOKING_IDS.includes(this.props.contactMainStatusId) && 
          this.props.selectedProject.customUserInterfaceId === 2
        ) || !this.props.siblingDisabled 
      ) ) {

      this.props.parent.contactStatus.current.handleClickSave()

    } else {

      const endOfDay = moment().endOf('day')

      if (
        this.props.currentCallType === 'noShow' &&
        this.props.noShowBookings.length
      ) {
        const id = this.findNextId(this.props.noShowBookings)        
        this.props.getContactCardById(id, 'noShow', updateCallTypeLists);
      } else if (
        this.props.currentCallType === 'callback' &&
        this.props.callbacks.filter(callback => moment(callback.startTime).isBefore(endOfDay)).length
      ) {
        const id = this.findNextId(
          this.props.callbacks
            .filter(callback => moment(callback.startTime).isBefore(endOfDay))
        )
        this.props.getContactCardById(id, 'callback', updateCallTypeLists);
      } else if (
        this.props.currentCallType === 'tentative' &&
        this.props.tentatives.length
      ) {
        const id = this.findNextId(this.props.tentatives)        
        this.props.getContactCardById(id, 'tentative', updateCallTypeLists);
      } else if (
        this.props.currentCallType === 'confirmation' &&
        this.props.confirmations.length
      ) {
        const id = this.findNextId(this.props.confirmations)        
        this.props.getContactCardById(id, 'confirmation', updateCallTypeLists);
      } else {
        this.props.resetCurrentCallType();
        this.props.getContactCard(
          updateCallTypeLists,
          null,
          this.props.selectedProject.customUserInterfaceId === 2
        );
      }

    }

  }

  findNextId(array) {
    let i = array.findIndex(e => e.id === (this.props.contact || {}).id) + 1
    if ( i >= array.length ) i = 0
    return array[i].id
  }

  isNextDisabled() {

    if ( this.props.disableNext ) return true

    if ( this.props.selectedProject.customUserInterfaceId === 2 ) {
      if ( this.props.cardState && this.props.people ) {
        const found = this.props.people.find(person => this.props.cardState[person.id])
        if ( found ) return true
      }
      if ( [2, 3].includes(this.props.contactMainStatusId) ) {  // 2: Kieltäytynyt, 3: Uusintasoitto
        if ( !(this.props.contactMainStatusId === 3 && this.props.switchChecked) ) {

          if ( !this.props.tags || !this.props.tags.length ) return true
          const people = (this.props.people || []).filter(
            person => (this.props.tags || []).find(tag => tag.id === person.id)
          )
          if ( people.find(person => !person.unknown && (!person.tag || !person.tag.length) ) ) {
            return true
          }
        }
      } else if ( CONTACT_BOOKING_IDS.includes(this.props.contactMainStatusId) ) {  // 6: Buukattu
        if ( this.props.currentCallType === 'noShow' && 
          this.props.bookingStatusId === 5 ) {
          return false
        } else {
          const people = (this.props.people || []).filter(
            person => (this.props.tags || []).find(tag => tag.id === person.id)
          )
          if ( people.find(person => 
              person.unknown || 
              !person.email || 
              !person.email.length || 
              !person.tag || 
              !person.tag.length|| 
              !person.tag2 || 
              !person.tag2.length ) 
          ) {
            return true
          }
        }
      }
    }

    // Disable button if call is active,
    // or status has not been updated when there was an answer to the call
    // or status has been updated without making the call

    if ( 
      !this.props.call.active 
      && !this.props.reservation 
      && this.props.selectedProject.allowSkipping 
      && this.props.contactMainStatusId === 1 ) {
      return false
    }

    if ( this.isConfirmationCase() ) return true
      
    if ( this.props.call.active || this.props.reservation !== null) {
      return true;
    }

    if ( this.props.contactMainStatusId === 3 
      && this.props.currentCallType === 'callback'
      && this.props.status.superType === 'callback' 
      && this.props.selectedProject.allowSkipping ) {
        return false
    }

    if ( this.props.contactMainStatusId === 9
      && this.props.currentCallType === 'tentative'
      && this.props.status.superType === 'tentative' 
      && this.props.selectedProject.allowSkipping ) {
        return false
    }
    
    if ( this.props.contactMainStatusId === 10 && this.props.currentCallType === 'noShow' ) {
      return false
    }

    if ( this.props.siblingDisabled !== undefined && this.props.siblingDisabled ) {
      return true
    }

    if ( this.props.contactCard && 
      this.props.selectedProject.customUserInterfaceId === 2 && 
      ( !this.props.currentCallType || this.props.currentCallType === 'booking' ) 
    ) {
      if ( this.props.contactCard.statusId !== 1 ) {
        return false
      }
    }

    if ( this.props.status !== undefined &&
      !this.props.contactIdParam &&
      (
        (this.props.status.superType === 'open' && !this.props.call.finished) ||
        (this.props.status.superType !== 'open' && !this.props.call.finished) 
      )
    ) {
      return true
    }

    return false;
  }

  render() {
    const callButtonStyle = this.props.call.active && this.props.call.origin == null ? 'danger' : 'success'
    const displayTimer = (window.enableCallDelayTimer && this.props.callDelayTimer > 0) && callButtonStyle === 'success'
    const NOSHOW_CALL_DURATION = 40
    const PERCENT_DONE = Math.min(
      100, 
      Math.floor(( this.props.callDuration / NOSHOW_CALL_DURATION) * 100)
    )
    const GREEN = '#49c231'

    return (
      <div className="contact-header">
        <div>
          <ContactHeaderName 
            showLinkToContactPage={ this.props.showLinkToContactPage } 
            showBusinessId={ this.props.showBusinessId } />
        </div>
        <div style={{ display: 'flex', flexFlow: 'column', padding: 0 }}>
          <div style={{ display: 'flex', padding: 0 }}>
            <div>
              <Button
                bsStyle={callButtonStyle}
                disabled={ displayTimer || ( this.props.call.pending || ( this.props.call.active && this.props.call.origin != null ) ) || (
                  (this.props.currentCallType === 'confirmation' && this.props.call.finished)) }
                onClick={() => this.handleClickCall()}
              >
                {this.props.call.active  && this.props.call.origin == null 
                  ? this.props.t('call.endCall')
                  : ( 
                    this.props.call.pending 
                      ? this.props.t('call.startingCall') 
                      : this.props.t('call.startCall') 
                  )
                }{
                  displayTimer && ` (${this.props.callDelayTimer})`
                }
              </Button>
              {
                this.props.currentCallType === 'noShow' && 
                this.props.contact?.project?.noShowExceptions &&
                <div style={{
                  opacity: this.props.call.active ? 1 : 0,
                  pointerEvents: 'none',
                  width: 'fit-content', 
                  borderRadius: '50%', 
                  boxShadow: 'rgba(0, 0, 0, 0.6) 1px 1px 3px',
                  position: 'absolute',
                  marginTop: '-56px',
                  marginLeft: '-21px',
                  background: `conic-gradient(${GREEN} ${PERCENT_DONE}%, white 0% 100%)`,
                  width: 38,
                  height: 38,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '.8em',
                  padding: '2px'
                }}>
                  <div style={{
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: PERCENT_DONE >= 100 ? GREEN : 'white',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: PERCENT_DONE >= 100 ? 'white' : 'unset'
                  }}>
                  {
                     moment("00:00:00.000", "hh:mm:ss.SSS")
                      .add(this.props.callDuration, "s")
                      .format("mm:ss")
                  }    
                  </div>            
                </div>
              }
            </div>
            <div>
              <Button
                style={{ width: "210px"}}
                bsStyle="primary"
                disabled={this.isNextDisabled() || this.props.disableNext}
                onClick={() => this.handleClickNext()}
              >
              { this.props.t('call.saveAndNextContact')}
              </Button>
            </div>
          </div>
        </div>

        {this.props.pathname !== getPath('/call') &&
          this.props.reservation === null &&
          !this.props.hideContactButton && (
          <div>
            <Button bsStyle="link" onClick={() => this.props.redirect(getPath('/call') + (this.props.pathname === getPath('/call/suggestions') ? '#open' : '' ))}>
              {this.props.t('contactInfo.contact')}
            </Button>
          </div>
        )}

        {/* Show reschedule link for regular and callback call types */}
        {!this.props.hideFreeTimesButton &&
          this.props.pathname !== getPath('/call/suggestions') &&
          this.props.status !== undefined &&
          (this.props.status.superType === 'open' ||
            this.props.status.superType === 'callback') && (
            <div>
              <Button
                bsStyle="link"
                disabled={
                  this.props.status?.superType === 'open' && 
                  this.props.contactMainStatusId &&
                  this.props.contactMainStatusId !== 1  
                }
                onClick={() => this.props.redirect(getPath('/call/suggestions'))}
              >
                {this.props.t('contactInfo.freeTimes')}
              </Button>
            </div>
          )}

        {/* Show reschedule link for noShow and tentative call types
        when user is in the booking route */}
        {this.props.contact.bookings !== undefined &&
          this.props.contact.bookings.length > 0 &&
          this.props.status !== undefined &&
          (this.props.status.superType === 'noShow' ||
            this.props.status.superType === 'tentative' ||
            this.props.currentCallType === 'confirmation' ) &&
          this.props.pathname ===
            `/bookings/${this.props.contact.bookings[0].id}` && (
            <div>
              <Button
                bsStyle="link"
                onClick={() => this.props.redirect(getPath('/call/suggestions'))}
              >
                {this.props.t('booking.reschedule')}
              </Button>
            </div>
          )}

        {/* Show reservation link for normal call flow */}
        {!this.props.pathname.startsWith('/bookings') &&
          this.props.reservation !== null &&
          this.props.status !== undefined &&
          (this.props.status.superType !== 'noShow' &&
            this.props.status.superType !== 'tentative' &&
            this.props.currentCallType !== 'confirmation') && (
            <div>
              <Button
                bsStyle="link"
                onClick={() =>
                  this.props.redirect(
                    `/bookings/${this.props.reservation.bookingId}`
                  )
                }
              >
                {this.props.t('call.reservation')}
              </Button>
            </div>
          )}

        {/* Show booking link for noShow and tentative call types */}
        {!this.props.hideBookingButton && !this.props.pathname.startsWith('/bookings') &&
          (this.props.contact.bookings !== undefined &&
            this.props.contact.bookings[0] !== undefined &&
            this.props.status !== undefined &&
            (this.props.status.superType === 'noShow' ||
              this.props.status.superType === 'tentative' ||
              this.props.currentCallType === 'confirmation')) && (
            <div>
              <Button
                bsStyle="link"
                onClick={() =>
                  this.props.redirect(
                    `/bookings/${this.props.contact.bookings[0].id}`
                  )
                }
              >
                {this.props.t('booking.booking')}
              </Button>
            </div>
          )}

        <Modal show={this.state.showModal}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{this.props.t('call.didContactAnswer')}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.closeModal(true)}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.closeModal(false)}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  booking: state.booking.booking,
  disableNext: state.booking.disableNext,
  call: state.call.call,
  callDuration: state.call.callDuration,
  callDelayTimer: state.call.delayTimer,
  callbacks: state.callLists.callbacks,
  confirmations: state.callLists.confirmations,
  contact: state.contacts.contact,
  contactCard: state.contacts.contactCard,
  contactStatuses: state.contacts.contactStatuses,
  currentCallType: state.callLists.current,
  includeNotInterested: state.callLists.includeNotInterested,
  noShowBookings: state.callLists.noShowBookings,
  onlyOwn: state.callLists.onlyOwn,
  pathname: state.router.location ? state.router.location.pathname : '/',
  reservation: state.booking.reservation,
  status: state.contacts.contact.status,
  tentatives: state.callLists.tentatives,
  selectedNoShowServicePoint: state.callLists.selectedNoShowServicePoint,
  selectedNoShowPostalAreas: state.callLists.selectedNoShowPostalAreas,
  selectedNoShowRepresentatives: state.callLists.selectedNoShowRepresentatives,
  selectedNoShowBooker: state.callLists.selectedNoShowBooker,
  selectedNoShowYear: state.callLists.selectedNoShowYear,
  selectedNoShowMonth: state.callLists.selectedNoShowMonth,
  selectedNoShowStatus: state.callLists.selectedNoShowStatus,
  noShowSokContacts: state.callLists.noShowSokContacts,
  selectedProject: state.contacts.contact?.project || {},
  useFlow: (state.user.booker || {}).useFlow === true
});

const mapDispatchToProps = dispatch => ({
  endCall: (answered, type, bookingId) => {
    dispatch(
      callActions.endCall({
        answered,
        type,
        bookingId
      })
    );
  },
  getContactCard: (updateCallTypeLists, skipContactId, lastCallDone=false) => {
    const payload = { updateCallTypeLists, skipContactId }
    if ( lastCallDone ) payload.lastCallDone = true
    dispatch(contactActions.getContactCard(payload));
    dispatch(push(getPath('/call')));
  },
  getContactCardById: (contactId, type, updateCallTypeLists) => {
    dispatch(contactActions.getContactCard({ contactId, type, updateCallTypeLists }));
    dispatch(push(getPath('/call')));
  },
  redirect: url => {
    dispatch(push(url));
  },
  resetCurrentCallType: () => {
    dispatch(callListActions.resetCurrentCallType());
  },
  startCall: (phoneNumber, type, contactId) => {
    dispatch(
      callActions.startCall({
        phoneNumber,
        type: type || 'booking',
        contactId
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ContactHeader)
);
